var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "list" },
        _vm._l(_vm.list, function(item, index) {
          return _c("div", { key: index, staticClass: "item" }, [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "image-box" }, [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showImage(item),
                      expression: "showImage(item)"
                    }
                  ],
                  staticClass: "image",
                  attrs: { src: _vm.showImage(item) },
                  on: {
                    click: function($event) {
                      return _vm.previewImg(index)
                    }
                  }
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showImage(item),
                        expression: "!showImage(item)"
                      }
                    ],
                    staticClass: "image-null"
                  },
                  [_vm._v("当前模型暂无")]
                ),
                _vm.isNotNaN(item.title)
                  ? _c("div", { staticClass: "title-tag color-green" }, [
                      _vm._v(_vm._s(item.title))
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "tip",
                    style: { color: item.isConfirm ? "red" : "" }
                  },
                  [_vm._v(_vm._s(item.id))]
                ),
                _c(
                  "div",
                  { staticClass: "check-box" },
                  [
                    _c("a-checkbox", {
                      staticClass: "checked",
                      model: {
                        value: item.checkStatus,
                        callback: function($$v) {
                          _vm.$set(item, "checkStatus", $$v)
                        },
                        expression: "item.checkStatus"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "name" }, [
                _vm._v(" " + _vm._s(item.specialItemName) + " ")
              ])
            ]),
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c(
                  "a",
                  {
                    staticClass: "yuan-img-btn",
                    on: {
                      click: function($event) {
                        return _vm.showYuanImage(item)
                      }
                    }
                  },
                  [_vm._v("原图")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "move-btn",
                    on: {
                      click: function($event) {
                        return _vm.moveClick(item)
                      }
                    }
                  },
                  [_vm._v("挪动")]
                ),
                _c(
                  "a-popconfirm",
                  {
                    attrs: {
                      title: "确定要删除此项么？",
                      "ok-text": "确认",
                      "cancel-text": "取消"
                    },
                    on: {
                      confirm: function($event) {
                        return _vm.handleRemove(item.id)
                      }
                    }
                  },
                  [_c("a", [_vm._v("删除")])]
                )
              ],
              1
            )
          ])
        }),
        0
      ),
      _c("KaiYuanMove", {
        ref: "kaiYuanMoveEl",
        on: { success: _vm.editSuccess }
      }),
      _c("ShowYuanImage", { ref: "showYuanImageEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }