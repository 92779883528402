
<template>
  <div class="content">
    <div class="top">
      <!--   选择背字   -->
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="params.specialItemId"
          placeholder="请选择背字"
      >
        <a-select-option
            v-for="item of kaiYuanCoinBackTextList"
            :key="item.id"
            :value="item.id"
        >{{ item.name }}</a-select-option>
      </a-select>
      <!--   选择背字   -->
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="params.confirmType"
          placeholder="选择是否确认"
      >
        <a-select-option
            v-for="item of confirmTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-button
          @click="search"
          class="ml-5"
          type="primary"
          size="small"
      >搜索</a-button>
      <a-radio-group
          v-if="false"
          size="small"
          class="ml-5"
          v-model="imageType"
          :default-value="1"
          button-style="solid"
      >
        <a-radio-button :value="0">
          正面图
        </a-radio-button>
        <a-radio-button :value="1">
          背面图
        </a-radio-button>
      </a-radio-group>
      <div class="select-all-text">
        <a-checkbox
            v-model="checkAll"
            :indeterminate="indeterminate"
            @change="onCheckAllChange"
        >
          全选
        </a-checkbox>
      </div>
      <div class="change-box">
        <a-popconfirm
            title="确定吗？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleAllConfirm('confirm')"
        >
          <a-button
              size="small"
              style="margin-right: 5px"
              type="danger"
          >批量确定</a-button>
        </a-popconfirm>
        <a-button
            size="small"
            class="ml-5"
            type=""
            @click="handleBatchMove"
        >批量挪动</a-button>
      </div>
      <a-button
          size="small"
          class="ml-5"
          @click="uploadClick()"
      >手动上传</a-button>
    </div>
    <div class="table-content">
      <KaiYuanList
          :image-type="imageType"
          :list="listData"
          @editSuccess="handleEditSuccess($event)"
          @deleteSuccess="addSuccess"
      ></KaiYuanList>
      <div class="pagination">
        <a-pagination
            :pageSize.sync="pagination.pageSize"
            :current="pagination.current"
            :total="pagination.total"
            @change="changePage"
        />

      </div>
    </div>
    <KaiYuanAdd
        ref="kaiYuanAddEl"
        @success="addSuccess"
    />
<!--  挪动  -->
    <KaiYuanMove ref="kaiYuanMoveEl" @success="addSuccess"/>
  </div>
</template>
<script>
import KaiYuanList from "@/views/cmsPage/versionManage/kaiYuanCoinByBackManage/_components/KaiYuanList"
import KaiYuanAdd from "@/views/cmsPage/versionManage/kaiYuanCoinByBackManage/_components/KaiYuanAdd"
import untils from "@/untils";
import {confirmTypeList} from "@/views/cmsPage/versionManage/kaiYuanCoinByBackManage/_data"
import KaiYuanMove from "@/views/cmsPage/versionManage/kaiYuanCoinByBackManage/_components/KaiYuanMove/index.vue"

export default {
  components: {
    KaiYuanList,
    KaiYuanAdd,
    KaiYuanMove
  },
  data() {
    return {
      untils,
      checkAll: false,
      indeterminate: false,
      imageType: 1,
      listData: [],
      confirmTypeList: confirmTypeList,
      params: {
        pageSize: 30,
        pageNum: 1,
        specialItemId: undefined,
      },
      kaiYuanCoinBackTextList: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
        showTotal: (total) => `共${total}条`,
      },
    }
  },
  async created() {
    await this.getList()
    await this.getKaiYuanCoinBackText()
  },
  watch: {
    listData: {
      deep: true,
      handler() {
        let listStatus = []
        const listLength = this.listData.length
        this.listData.forEach(item => {
          if (item.checkStatus) {
            listStatus.push(item.id)
          }
        })
        if (listStatus.length === listLength) {
          this.checkAll = true
          this.indeterminate = false
        }
        if (listStatus.length > 0 && listStatus.length < listLength) {
          this.indeterminate = true
        }
        if (listStatus.length === 0) {
          this.checkAll = false
          this.indeterminate = false
        }
      }
    }
  },
  methods: {
    /** 批量挪动 */
    handleBatchMove() {
      let kaiYuanId = []
      this.listData.forEach(el => {
        if (el.checkStatus) {
          kaiYuanId.push(el.id)
        }
      })
      if (kaiYuanId && kaiYuanId.length) {
        this.$refs.kaiYuanMoveEl.show(kaiYuanId, 1, 'all')
      } else {
        return this.$message.warning('请选择需要挪动的币种')
      }
    },
    /** 修改全选 */
    onCheckAllChange(e) {
      if (e.target.checked) {
        this.listData.forEach(item => {
          item.checkStatus = true
        })
      } else {
        this.listData.forEach(item => {
          item.checkStatus = false
        })
      }
    },
    /** 批量确认 */
    async handleAllConfirm() {
      let kaiYuanId = []
      this.listData.forEach(el => {
        if (el.checkStatus) {
          kaiYuanId.push(el.id)
        }
      })
      if (kaiYuanId && kaiYuanId.length) {
        const ids = kaiYuanId.join(',')
        const res = await this.axios("/dq_admin/duibanKaiyuanSpecialItemData/batchConfirm", {
          params: {
            ids: ids
          }
        });
        if (res.status != 200) return;
        this.$message.success(res.message|| '成功')
        await this.getList()
      } else {
        return this.$message.warning('请选择需要确定的币种')
      }
    },
    /** 挪动数据 */
    handleEditSuccess(id) {
      const foundIndex = this.listData.findIndex(el => el.id === id)
      this.listData.splice(foundIndex, 1)
    },
    /** 上传成功 */
    addSuccess() {
      this.getList();
    },
    /** 点击上传 */
    uploadClick() {
      this.$refs["kaiYuanAddEl"].show();
    },
    /** 获取列表数据 */
    async getList() {
      const res = await this.axios("/dq_admin/duibanKaiyuanSpecialItemData/listDatas", {
        params: this.params
      });
      if (res.status !== '200') return;
      this.checkAll = false
      this.indeterminate = false
      let list = res.data.records;
      list.forEach(item => {
        item.checkStatus = false
      })
      this.listData = list
      this.pagination.total = res.data.total
    },
    /** 获取开元大币种背字 */
    getKaiYuanCoinBackText(){
      this.axios('/dq_admin/duibanKaiyuanSpecialItemData/listItems', {
        params: { type: 1 }
      }).then(res=>{
        if(res.status === '200'){
          this.kaiYuanCoinBackTextList = res.data
        }
      })
    },
    changePage(page) {
      this.pagination.current = page;
      this.params.pageNum = page
      this.getList();
    },
    search() {
      this.pagination.current = 1
      this.params.pageNum = 1
      this.getList()
    }
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.bgf {
  background-color: #fff !important;
  border: 1px solid #000c17 !important;
  color: #000c17 !important;
  margin-left: 20px !important;
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
.ml-5 {
  margin-left: 5px;
}
</style>
