var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: 800,
        title: "手动上传",
        "ok-text": "确认",
        "cancel-text": "取消"
      },
      on: { cancel: _vm.cancelPushModal, ok: _vm.confirmPushModal },
      model: {
        value: _vm.pushModal,
        callback: function($$v) {
          _vm.pushModal = $$v
        },
        expression: "pushModal"
      }
    },
    [
      _c("div", { staticClass: "push-modal" }, [
        _c(
          "div",
          [
            false
              ? _c(
                  "a-select",
                  {
                    staticStyle: { width: "180px" },
                    attrs: {
                      "filter-option": _vm.untils.filterOption,
                      showSearch: "",
                      size: "small",
                      placeholder: "请选择正面或反面"
                    },
                    on: { change: _vm.handleChangeSelectedType },
                    model: {
                      value: _vm.params.type,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "type", $$v)
                      },
                      expression: "params.type"
                    }
                  },
                  _vm._l(_vm.upLoadImageListType, function(item, index) {
                    return _c(
                      "a-select-option",
                      { key: index, attrs: { value: item.value } },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  1
                )
              : _vm._e(),
            _c(
              "a-select",
              {
                staticStyle: { margin: "5px", width: "180px" },
                attrs: {
                  dropdownMatchSelectWidth: false,
                  "filter-option": _vm.untils.filterOption,
                  allowClear: "",
                  showSearch: "",
                  size: "small",
                  placeholder: "请选择背字"
                },
                model: {
                  value: _vm.params.specialItemId,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "specialItemId", $$v)
                  },
                  expression: "params.specialItemId"
                }
              },
              _vm._l(_vm.kaiYuanCoinBackTextList, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.id } },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              1
            )
          ],
          1
        ),
        _vm.params.specialItemId
          ? _c("div", { staticClass: "images" }, [
              _c("div", { staticClass: "images-title" }, [
                _c("div", [_vm._v(_vm._s(false ? "正面图" : "反面图"))])
              ]),
              _c("div", { staticClass: "up-image" }, [
                _c(
                  "div",
                  { staticClass: "up-left" },
                  [
                    _c(
                      "a-upload",
                      {
                        key: _vm.imgKey,
                        attrs: {
                          action: _vm.ip + "/file/upload",
                          "list-type": "picture-card",
                          "file-list": _vm.coverList,
                          multiple: ""
                        },
                        on: {
                          preview: _vm.handlePreview,
                          change: _vm.handleChangeCoverList
                        }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("a-icon", { attrs: { type: "plus" } }),
                            _c("div", { staticClass: "ant-upload-text" }, [
                              _vm._v("上传图片")
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }