<template>
  <a-modal
      v-model="showModel"
      :width='800'
      title="原图"
      :footer="null"
      @cancel="close"
  >
    <div class="content">
      <div class="img" :class="imgInfo.backImage ? '' : 'w-100'" v-if="imgInfo.frontImage">
        <img :src="imgInfo.frontImage" alt="">
      </div>
      <div class="img" :class="imgInfo.frontImage ? '' : 'w-100'" v-if="imgInfo.backImage">
        <img :src="imgInfo.backImage" alt="">
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      showModel: false,
      imgInfo: ''
    };
  },
  methods: {
    /**开启*/
    show(data) {
      this.imgInfo = data
      this.showModel = true;
    },
    /** 关闭 */
    close() {
      this.imgInfo = ''
      this.showModel = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.img {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
}
.w-100 {
  width: 100% !important;
}
</style>