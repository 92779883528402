<template>
  <div>
    <div class="list">
      <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
      >
        <div class="content">
          <div class="image-box">
            <img
                v-show="showImage(item)"
                class="image"
                :src="showImage(item)"
                @click="previewImg(index)"
            />
            <div v-show="!showImage(item)" class="image-null">当前模型暂无</div>
            <div class="title-tag color-green" v-if="isNotNaN(item.title)">{{ item.title }}</div>
            <div class="tip" :style="{color: item.isConfirm ? 'red' : ''}">{{item.id}}</div>
            <div class="check-box">
              <a-checkbox class="checked" v-model:checked="item.checkStatus"></a-checkbox>
            </div>
          </div>

          <div class="name">
            {{item.specialItemName}}
          </div>
        </div>
        <div class="footer">
          <a
              class="yuan-img-btn"
              @click="showYuanImage(item)"
          >原图</a>
          <a
              class="move-btn"
              @click="moveClick(item)"
          >挪动</a>
          <a-popconfirm
              title="确定要删除此项么？"
              ok-text="确认"
              cancel-text="取消"
              @confirm="handleRemove(item.id)"
          >
            <a>删除</a>
          </a-popconfirm>
        </div>
      </div>
    </div>
    <KaiYuanMove
        ref="kaiYuanMoveEl"
        @success="editSuccess"
    ></KaiYuanMove>
    <ShowYuanImage
      ref="showYuanImageEl"
    />
  </div>

</template>

<script>
import KaiYuanMove from "@/views/cmsPage/versionManage/kaiYuanCoinByBackManage/_components/KaiYuanMove"
import ShowYuanImage from "@/views/cmsPage/versionManage/kaiYuanCoinByBackManage/_components/ShowYuanImage"
export default {
  props: {
    list: {
      default: [],
    },
    imageType: {
      default: 0
    }
  },
  components: {
    KaiYuanMove,
    ShowYuanImage
  },
  computed: {
    isNotNaN() {
      return title => !isNaN(parseFloat(title)) && isFinite(title);
    }
  },
  methods: {
    showYuanImage(item) {
      this.$refs.showYuanImageEl.show(item)
    },
    showImage(item) {
      if (this.imageType === 0) {
        return item.cutFrontImage || item.frontImage;
      } else {
        return item.cutBackImage || item.backImage;
      }
    },
    /** 删除 */
    handleRemove(id) {
      this.axios("/dq_admin/duibanKaiyuanSpecialItemData/deleteById", {
        params: { id: id },
      }).then((res) => {
        if (res.status !== '200') return
        this.$emit("deleteSuccess");
        this.$message.success("删除成功");
      });
    },
    editSuccess(kaiYuanId) {
      this.$emit("editSuccess", kaiYuanId);
    },
    moveClick(item) {
      this.$refs["kaiYuanMoveEl"].show(item.id, this.imageType);
    },
    // 预览
    previewImg(index) {
      const list = this.list.map((el) => {
        return {img_url: this.imageType === 0 ? el.frontImage : el.backImage}
      });
      this.$previewImg({
        current: index,
        list: list,
        baseImgField: "img_url",
        showMute: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content {
      margin-top: 10px;
      .image-box {
        position: relative;
        .tool-image-item {
          width: 300px;
          height: 300px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
        .image {
          width: 300px;
          height: 300px;
          border-radius: 4px;
        }
        .check-box {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 70px;
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          .checked {
            width: 70px;
            height: 70px;
            label {
              span {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .tip {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 80px;
          height: 20px;
          color: black;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          border-radius: 4px;
        }
        .title-tag {
          position: absolute;
          left: 0;
          top: 0;
          width: 80px;
          height: 20px;
          color: black;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          border-radius: 4px;
        }
      }

      .name,
      .score {
        text-align: center;
        margin-top: 2px;
      }
    }
    .footer {
      margin-top: 2px;
      a {
        color: red;
        margin-right: 10px;
        &:nth-last-of-type(1) {
          margin-right: 0;
        }
      }
      .move-btn {
        color: #1890ff;
      }
      .yuan-img-btn {
        color: #1890ff;
      }
    }
  }
}
.image-null {
  width: 300px;
  height: 300px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  color: beige;
  font-size: 24px;
}
</style>