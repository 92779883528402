var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 800, title: "原图", footer: null },
      on: { cancel: _vm.close },
      model: {
        value: _vm.showModel,
        callback: function($$v) {
          _vm.showModel = $$v
        },
        expression: "showModel"
      }
    },
    [
      _c("div", { staticClass: "content" }, [
        _vm.imgInfo.frontImage
          ? _c(
              "div",
              {
                staticClass: "img",
                class: _vm.imgInfo.backImage ? "" : "w-100"
              },
              [_c("img", { attrs: { src: _vm.imgInfo.frontImage, alt: "" } })]
            )
          : _vm._e(),
        _vm.imgInfo.backImage
          ? _c(
              "div",
              {
                staticClass: "img",
                class: _vm.imgInfo.frontImage ? "" : "w-100"
              },
              [_c("img", { attrs: { src: _vm.imgInfo.backImage, alt: "" } })]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }