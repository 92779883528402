<template>
  <a-modal
      v-model="pushModal"
      :width='800'
      title="手动上传"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancelPushModal"
      @ok="confirmPushModal"
  >
    <div class="push-modal">
      <div>
        <a-select
            v-if="false"
            v-model="params.type"
            :filter-option="untils.filterOption"
            showSearch
            style="width: 180px"
            size="small"
            @change="handleChangeSelectedType"
            placeholder="请选择正面或反面"
        >
          <a-select-option
              v-for="(item, index) of upLoadImageListType"
              :key="index"
              :value="item.value"
          >{{ item.name }}</a-select-option>
        </a-select>
        <a-select
            :dropdownMatchSelectWidth="false"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 180px"
            v-model="params.specialItemId"
            placeholder="请选择背字"
        >
          <a-select-option
              v-for="item of kaiYuanCoinBackTextList"
              :key="item.id"
              :value="item.id"
          >{{ item.name }}</a-select-option>
        </a-select>
      </div>
      <div class="images" v-if="params.specialItemId">
        <div class="images-title">
          <div>{{ false ? '正面图' : '反面图' }}</div>
        </div>
        <div class="up-image">
          <div class="up-left">
            <a-upload
                :key="imgKey"
                :action="ip + '/file/upload'"
                list-type="picture-card"
                :file-list="coverList"
                multiple
                @preview="handlePreview"
                @change="handleChangeCoverList"
            >
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from "vuex";
import untils from "@/untils";
import {upLoadImageListType} from "@/views/cmsPage/versionManage/kaiYuanCoinByBackManage/_data"
export default {
  data() {
    return {
      untils,
      pushModal: false,
      coverList: [],
      imgKey: Math.random(),
      upLoadImageListType: upLoadImageListType,
      params: {
        specialItemId: undefined,
      },
      kaiYuanCoinBackTextList: [],
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  watch: {
    pushModal() {
      if (!this.pushModal) {
        this.coverList = [];
        this.params = {
          specialItemId: undefined,
        }
      }
    },
  },
  methods: {
    async show() {
      await this.getKaiYuanCoinBackText(1)
      this.pushModal = true;
    },
    /** 更改修改的类型 */
    handleChangeSelectedType(type) {
      this.getKaiYuanCoinBackText(type)
    },
    /** 获取开元大币种背字 */
    getKaiYuanCoinBackText(type){
      this.axios('/dq_admin/duibanKaiyuanSpecialItemData/listItems', {
        params: { type: type }
      }).then(res=>{
        if(res.status === '200'){
          this.kaiYuanCoinBackTextList = res.data
        }
      })
    },
    /** 上传文件列表 */
    handleChangeCoverList({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {console.log(e)}
      });
      this.coverList = fileList;
    },
    /** 预览文件 */
    handlePreview(file) {
      const picturesList = this.coverList.map((row) => {
        return row.url;
      });
      const curIndex = picturesList.findIndex(el=>{
        return el === file.url
      })
      if (picturesList && picturesList.length > 0) {
        this.$previewImg({
          list: picturesList.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),
          current: curIndex,
          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    // 确认
    async confirmPushModal() {
      const coverImages = this.coverList.map(el => {
        return el.url
      });

      const res = await this.axios.post("/dq_admin/duibanKaiyuanSpecialItemData/manualBatchUpload", {
        ...this.params,
        imageList: coverImages
      });
      if (res.status != 200) return;
      this.cancelPushModal();
      this.$message.success(res.message || "操作成功");
      this.$emit("success");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ant-checkbox-wrapper {
  margin-left: 0 !important;
}
.images {
  width: 350px;
  margin-top: 10px;
  .images-title {
    width: 208px;
    display: flex;
    margin-bottom: 10px;
    div {
      width: 104px;
      flex: 1;
      text-align: center;
      margin-right: 10px;
    }
  }
  .up-image {
    display: flex;
    .up-left {
      width: 500px;
      display: flex;
      flex-wrap: wrap;
    }
    .up-right {
      .right-list {
        width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
      .right-item {
        width: 150px;
        height: 104px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>