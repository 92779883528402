var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: 800,
        title: "修改",
        "ok-text": "挪动",
        "cancel-text": "取消"
      },
      on: { cancel: _vm.close, ok: _vm.confirmPushModal },
      model: {
        value: _vm.pushModal,
        callback: function($$v) {
          _vm.pushModal = $$v
        },
        expression: "pushModal"
      }
    },
    [
      _c(
        "div",
        { staticClass: "bei-text-list" },
        _vm._l(_vm.kaiYuanCoinBackTextList, function(tag) {
          return _c(
            "div",
            {
              key: tag.id,
              staticClass: "mr-10 mb-10 cur-pot font-size-32 p-10 bei-text-tag",
              class: tag.id === _vm.params.specialItemId ? "active-tag" : "",
              on: {
                click: function($event) {
                  return _vm.handleSelectedTag(tag.id)
                }
              }
            },
            [_vm._v(_vm._s(tag.name))]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }