<template>
  <a-modal
      v-model="pushModal"
      :width='800'
      title="修改"
      ok-text="挪动"
      cancel-text="取消"
      @cancel="close"
      @ok="confirmPushModal"
  >
    <!--   选择背字   -->
    <div class="bei-text-list" >
      <div
          v-for="tag in kaiYuanCoinBackTextList"
          :key="tag.id"
          :class="tag.id === params.specialItemId ? 'active-tag' : ''"
          class="mr-10 mb-10 cur-pot font-size-32 p-10 bei-text-tag"
          @click="handleSelectedTag(tag.id)"
      >{{ tag.name }}</div>
    </div>
  </a-modal>
</template>

<script>
import untils from "@/untils";
export default {
  data() {
    return {
      untils,
      pushModal: false,
      kaiYuanId: '',
      kaiYuanCoinBackTextList: [],
      params: {
        idList: undefined,
        type: undefined,
        specialItemId: undefined
      },
      sourceType: 'list'
    };
  },
  methods: {
    /** 选择tag */
    handleSelectedTag(tagId) {
      this.params.specialItemId = tagId
    },
    /**开启*/
    async show(data, type, source) {
      this.sourceType = source || 'list'
      if (source === 'all') {
        this.params.idList = data
      } else {
        this.params.idList = [data]
        this.kaiYuanId = data
      }
      this.params.type = type
      await this.getKaiYuanCoinBackText(type)
      this.pushModal = true;
    },
    /** 获取开元大币种背字 */
    async getKaiYuanCoinBackText(type){
      await this.axios('/dq_admin/duibanKaiyuanSpecialItemData/listItems', {
        params: { type: type }
      }).then(res=>{
        if(res.status === '200'){
          this.kaiYuanCoinBackTextList = res.data
        }
      })
    },
    /** 关闭 */
    close() {
      this.pushModal = false;
      this.params = {
        id: undefined,
        type: undefined,
        specialItemId: undefined
      }
    },
    /** 确定 */
    async confirmPushModal() {
      this.$loading.show();
      const res = await this.axios.post("/dq_admin/duibanKaiyuanSpecialItemData/updateData", this.params);
      this.$loading.hide();
      if (res.status != 200) return;
      this.close();
      this.$message.success(res.message || "操作成功");
      this.$emit('success', this.kaiYuanId)
    },

    handleChangeCoin(e) {
      this.coinKindId = e
      this.coinKindName = this.coinList.find(el => el.id === e).coinKindName
    },
  },
};
</script>

<style lang="scss" scoped>
.lately-search {
  margin-top: 20px;
  button {
    margin-right: 15px;
  }
}
.header-item {
  margin-right: 5px;
  margin-bottom: 5px;
  width: 260px;
}
.bei-text-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.bei-text-tag {
  padding: 10px 20px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid gray;
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.active-tag {
  border: 1px solid #2d8cf0;
  background-color: green;
}
</style>